.custom-card {
    background-color: #ffff !important;
    padding: 10px;
}

.custom-cardbody {
    background-color: #e3e3e3;
    border-radius: 8px;
}

.input-field-class {
    width: 85%;
    background: none;
    font-weight: 600;
}

.form-color {
    background-color: #939393;
}

.label-width {
    width: auto;
    margin-bottom: 0px;
    color: #818283;
    font-size: 16px;
}

.p1-20 {
    padding: 12px 20px 0px 12px;
}

.btn-primary {
    background-color: #1A91D3;
    border: none;
    border-radius: 8px;
}

.p2-20 {
    padding: 12px 20px 12px 12px;
}

.custom-button {
    background-color: #e7e7e7 !important;
    width: 17%;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-right: 10px;
}

.custom-button:hover {
    background-color: #e7e7e7 !important;
    width: 17%;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-right: 10px;
}

.custom-button:active {
    background-color: #e7e7e7 !important;
    width: 17%;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-right: 10px;
}

.findmyway {
    width: 78%;
}

.icon-size {
    width: 18px !important;
    height: 18px !important;
}

.icon-size1 {
    width: 25px !important;
    height: 25px !important;
}

.icon-size2 {
    width: 20px !important;
    height: 20px !important;
}

.align-end {
    justify-content: space-between;
}

.serach-box {
    background-color: #e5e5e5 !important;
    height: 40px;
    font-size: 14px;
    padding-left: 35px;
}

.border-div {
    padding: 10px;
    border-bottom: 1px solid #C1C1C1;
}


/* //Tab styles */

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #1A91D3 !important;
    /* border-color: var(--bs-nav-tabs-link-active-border-color); */
    border-radius: 20px !important;
    margin-bottom: 12px !important;
}

.nav-tabs .nav-link {
    background: #e7e7e7 !important;
    color: black !important;
    border-radius: 20px !important;
    margin-bottom: 12px !important;
    padding: 3px 12px;
}

.nav-tabs .nav-item {
    /* margin-right: 8px; */
    font-size: 12px;
}

.header {
    font-size: 25px;
    font-weight: 700 !important;
    color: #000000;
}

.close-icon {
    height: 30px;
    width: 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #e5e5e5;
    border-radius: 50px;
    color: #666666;
    cursor: pointer;
}

.slide-content {
    position: absolute;
    top: 50%;
    left: 23%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.slide-content h2 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 600;
}

.slide-content p {
    color: #ffffff;
    /* margin-bottom: 7px !important; */
    font-size: 18px;
}

.slider {
    position: relative;
}

.imageStyle {
    max-width: 100%;
    /* height: 200px; */
}


/* Add these styles to your CSS */

.tabhead-container {
    overflow-x: auto;
    /* Enable horizontal scrolling */
}

.tabsalign {
    white-space: nowrap;
    /* Prevent line breaks for tabs */
    width: max-content;
    min-width: -webkit-fill-available;
}


/* Adjust the width based on the number of tabs and their content */
.Nav {
    width: 600px;
    /* Adjust the width as needed */
}

.right {
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: -13px;
    right: 0px;
    transform: translateY(-50%);
    color: #939393;
    cursor: pointer;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E5E5E5;
    font-size: 13px;
    z-index: 3000;
}

.left {
    width: 26px;
    height: 26px;
    right: 26px;
    bottom: 0;
    display: flex;
    position: absolute;
    color: #939393;
    z-index: 30000;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #E5E5E5;
    font-size: 13px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}

.column {
    /* border: 1px solid #ccc; */
    padding: 15px;
    background-color: #f3f3f3;
    border-radius: 3px;
}

.total {
    color: black;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}

.value {
    color: #26a3db;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
}

.floatLeft {
    float: left;
    /* margin-left: 100px; */
    white-space: nowrap;
}

.float-left {
    float: left;
}

.f-600 {
    font-weight: 600;
}

.btn-warning-clr {
    color: white;
    background-color: #FA9C75;
    border: none;
    font-weight: 500;
}

.btn-warning-clr:hover {
    color: white;
    background-color: #e98c65;
    border: none;
}

.btn-warning-clr:active {
    color: white;
    background-color: #e98c65;
    border: none;
}

.carousel-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.carousel-dot.selected {
    background-color: #333;
}

.custom-button-new {
    background-color: #f90d29 !important;
    border-color: #f90d29 !important;
    color: #fff !important;
    border-radius: 8px;
}

.custom-button-new:hover {
    background-color: #f90d29 !important;
    border-color: #f90d29 !important;
    color: #fff !important;
}

.custom-button-new:active {
    background-color: #f90d29 !important;
    border-color: #f90d29 !important;
    color: #fff !important;
}

.floor-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floors {
    position: absolute;
    right: 10px;
    top: 67px;
    border-radius: 10px;
    /* height: 90px; */
    background-color: white;
    width: 155px;
    z-index: 10;
}

.floors1 {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 10px;
    /* height: 90px; */
    background-color: white;
    width: 155px;
    z-index: 10;
}

.floor-number {
    color: #212530;
    text-decoration: none;
    border-bottom: 1px solid #00000033;
    width: 30px;
    text-align: center;
}

.floor-number:active {
    color: #1a91d3;
}

.floor-number:last-child {
    border-bottom: none;
}

.image-card-height {
    height: 59vh;
    background-color: #f3f3f3;
}

.Report-button {
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
    color: #1a91d3 !important;
}

.Report-button:hover {
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
    color: #1a91d3 !important;
}

.Report-button:active {
    background-color: #e8e8e8 !important;
    border-color: #e8e8e8 !important;
    color: #1a91d3 !important;
}

.light-heading {
    color: #818283;
    margin-bottom: 1px;
    font-weight: 400;
    font-size: 16px;
}

.text-color {
    color: #1a91d3;
}

.card {
    border: none !important;
}

.fill-image {
    height: -webkit-fill-available !important;
    position: absolute !important;
}

.content-card {
    padding: 12px 12px 12px 12px !important;
    background-color: #f7f7f7;
}

.btn-warning-color {
    background: #efaa3a;
    border: none;
}

.btn-warning-color:active {
    background: #efaa3a;
    border: none;
}

.btn-warning-color:hover {
    background: #efaa3a;
    border: none;
}

.f-w-600 {
    font-weight: 600;
}

.bg-color {
    background-color: #f7f7f7 !important;
    margin-bottom: 15px;
}

.alignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-line {
    width: 16px;
    height: 4px;
    top: calc(50% - 62px/2 + 13.5px);
    transform: rotate(-90deg);
    color: #000;
    margin-top: 11px;
}

.spacebtw {
    width: 8px;
    height: 4px;
}

.selector {
    /* position: relative; */
    width: 50%;
    background-color: var(--smoke-white);
    height: 36px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border-radius: 100px; */
    /* box-shadow: 0 0 16px rgb(0 0 0 / 20%); */
    float: right;
    /* border: 1px solid #922c88; */
    background: #dee2e69c;
    border-radius: 6px;

}

.selecotr-item {
    /* position: relative; */
    flex-basis: calc(70% / 1);
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selector-item_radio {
    appearance: none;
    display: none;
}

.selector-item_label {

    position: relative;
    height: 80%;
    width: 95%;
    text-align: center;
    /* border-radius: 9999px; */
    /* line-height: 368%; */
    font-weight: 600;
    /* transition-duration: .5s; */
    /* transition-property: color, box-shadow, -webkit-transform; */
    /* transition-property: transform, color, box-shadow; */
    /* transition-property: transform, color, box-shadow, -webkit-transform; */
    /* -webkit-transform: none; 
transform: none;  */
    padding-top: 3px;
    margin-bottom: 0px;
    /* margin-top: 3px; */

}

.selector-item_radio:checked+.selector-item_label {
    background-color: #1a91d3;
    color: white;

    border-radius: 5px;
}

.location-value {
    font-weight: 600;
    font-size: 16px;
    margin-left: 5px;
}

a {
    text-decoration: none !important;
}

.input-style {
    background-color: #e5e5e5 !important;
    border-color: #e5e5e5;
}

.input-style:focus {
    border-color: #e5e5e5;
    box-shadow: none;
}

.input-style:hover {
    border-color: #e5e5e5;
}

.imageUpload {
    height: 100px;
    width: 100px;
    background-color: #E9F6FB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 3px dotted #26A3DB;
    color: #26A3DB;
    font-size: 35px;
}

.previewImage {
    height: 100px;
    width: 100px;
    border-radius: 5px;
}

.position-relative {
    position: relative;
}

.remove-image {
    position: absolute;
    top: -5px;
    right: 6px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    font-size: 27px;
    color: red;
}

.button-option-icon {
    color: #666666;
    font-size: 25px;
}

.listHeading {
    font-weight: 700 !important;
}

.listcontent {
    font-size: 12px;
    color: #818284;
}

.number {
    font-size: 14px;
    color: #676767;
    font-weight: 600;
}

.logo-wrpr {
    position: absolute;
    top: 67px;
    left: 10px;
    /* width: 159px;
    height: 68px; */
    width: 180px;
    height: 80px;
    z-index: 2;
}

.logo-wrpr2 {
    position: absolute;
    top: 5px;
    left: 10px;
    /* width: 120px;
    height: 61px; */
    /* width: 159px;
    height: 68px; */
    width: 180px;
    height: 80px;
    z-index: 2;
}

.logo-img {
    height: 100%;
    width: 100%;
}

.not-found-div {
    background-color: #babbbc1f;
    border-radius: 10px;
    width: 85%;
    text-align: center;
    padding: 30px 10px;
}

.oops {
    font-size: 25px;
    font-weight: 600;
    color: #1A91D3;
}

.card .icon-div {
    height: 75px;
    width: 75px;
    background-color: #e5e5e5ba;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #6a6d73;
    font-weight: 500;
}

.icon-div:active {
    background-color: #1a91d3;
    color: white;

}

.note {
    font-size: 12px;
    background-color: #dff1fa;
    border-radius: 6px;
    padding: 10px;
}

.nodata {
    font-weight: 700;
}

.bottom-card {
    padding-bottom: 20px;
    border-radius: 10px;

    /* size responsive on large screen by sanoop01112024 */
    width: 100%;
    max-width: 425px;
    position: fixed;
    bottom: 0;
}

.bottom-row {
    margin-top: 5px;
}

hr {
    border-top: 1px solid #c0c0c0 !important;
    opacity: 1 !important;
}

.tab-content {
    margin-top: -15px;
}

.card {
    border-radius: 8px;
}

.img-location {
    max-width: 100%;
    /* height: auto; */
    /* height: 160px; */
    object-fit: contain;
    width: 100%;
}

.product-img {
    max-width: 100%;
    height: 170px;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.img-container-product {
    width: 170px;
    height: 170px;
    border-radius: 10px;
}

.btn-scndry {
    background-color: white !important;
    color: #666;
    border: 1px solid #666;
    font-size: 16px;
    padding: 4.4px 17px;
    /* font-weight: 500; */
}

.btn-scndry.disabled {
    color: #666;

    /* font-weight: 500; */
}

.btn-scndry:active {
    background-color: white !important;
    color: #666;
}

.btn-scndry:hover {
    background-color: white !important;
    color: #666;
}

/* .btn-scndry:hover {
    background-color: #a3a1a1 !important;
    color: #6a6d73;
    border: none;
  }
  
  
  
  .btn-scndry:focus {
    background-color: #a3a1a1 !important;
    color: #000;
    border: none;
  } */

input::-webkit-input-placeholder {
    color: #818283 !important;
}

textarea::-webkit-input-placeholder {
    color: #818283 !important;
}

.modal-sheet-container .react-modal-sheet-container {
    height: auto !important;
    background-color: #f7f7f7 !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 61px 75px !important;

}

/* .react-modal-sheet-container {
    animation-fill-mode: forwards;
    height: auto !important;
} */

.report-page-container .react-modal-sheet-container {
    height: 100vh !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    /* transform: none !important; */
}

.modal-backdrop {
    height: 120vh !important;
}

canvas {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* loader */

body {
    /* display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh; */
}

.bouncing-loader {
    display: flex;
    justify-content: center;
    margin: 40px auto;
}

.bouncing-loader>div {
    width: 16px;
    height: 16px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #1A91D3;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-16px);
    }
}

.bouncing-loader>div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
    animation-delay: 0.4s;
}

/* loader ends */
.loader-pos {
    /* height: 59vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.report-msge {
    font-size: 1rem;
    font-weight: 600;
}

.search-box {
    position: relative;
    display: flex;
    align-items: center;
}

.search-icon {
    position: absolute;
    left: 10px;
    color: #818283;
    font-size: 20px;
}

.magical-words {
    display: flex;
    justify-content: center;
    align-items: center;
}

.manageOpacity {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
}

.form-check-input:checked {
    background-color: #26A3DB;
    border-color: #26A3DB;
}

/* canvas {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    -webkit-font-smoothing: antialiased;
} */


.modal-close-icon {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
    font-weight: 600;
    z-index: 100;
}

.close-icon-vt {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E5E5E5;
    cursor: 'pointer';
    padding: 6px;
}

.imgslide-cont {
    width: 100%;
    height: 57px;
    overflow: hidden;
    position: absolute;
    z-index: 100;
    max-width: 425px;
    top: 5
}


/* size responsive on large screen by sanoop01112024 */

.main-cont {
    display: flex;
    justify-content: center;
}

.canvas-div {
    position: absolute;
}

.sheet-cont {
    max-width: 425px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.imgslider-div {
    display: flex;
    justify-content: center;
}



@media (min-width: 770px) {

    .floors {
        top: 10px;
    }

    .logo-wrpr {
        top: 0px;
    }

    .imgslide-cont {
        border-radius: 8px;
        top: 10px;
    }

    /* .imgslider-div{
        top:10px
    } */
}

/* size responsive on large screen by sanoop01112024 ends*/