body {
  margin: 0;
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto,
    "Impact", Arial, sans-serif, "Segoe UI", Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  touch-action: none !important;
  -ms-touch-action: manipulation;
  user-scalable: no;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.btn-primary {
  background: rgb(26, 145, 211) !important;
  border-width: 0px;
  border-style: solid;
  color: rgb(255, 255, 255);
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 4px;
  padding: 15px !important;
  /* height: 45px; */
  font-weight: 500;
  box-shadow: 0px 0px;
}

.btn-primary:hover {
  background: rgb(26, 145, 211) !important;
  border-width: 0px;
  border-style: solid;
  color: rgb(255, 255, 255);
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 4px;
  padding: 15px !important;
  /* height: 45px; */
  font-weight: 500;
  box-shadow: 0px 0px;
}

.btn-primary:active {
  background: rgb(26, 145, 211) !important;
  border-width: 0px;
  border-style: solid;
  color: rgb(255, 255, 255);
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 4px;
  padding: 15px !important;
  /* height: 45px; */
  font-weight: 500;
  box-shadow: 0px 0px;
}

.btn-secondary {
  padding: 15px !important;

  /* height: 45px; */
}

.f-14 {
  font-size: 14px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.modal .modal-footer {
  border-top: none;
  padding: 25px;
  padding-bottom: 40px;
}

.modal .modal-header {
  border-top: none;
  padding: 25px;
  font-weight: 600;
  font-size: 18px;
  border-bottom: none;
}

.modal .modal-body {
  padding: 20px;
}

.modalbody-padding {
  padding: 0px 25px 25px 25px !important;
}

.h5 {
  color: #000000;
}

/* style for swap destination and starting point method */
.card-body-container{
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.swap-arrow {
  transform: rotate(90deg);
  cursor: pointer;
}


/* styles for rotating the canvas */
.canvas-orientation{
  /* position:relative; */
}
.canvas-orientation.destination-rotate {
  position: initial;
}

.canvas-orientation.destination-rotate button {
  bottom: 175px;
}
.canvas-orientation button{
  position: absolute;
  right: 10px;
  top: auto;
  border-radius: 10px;
  background-color: white;
  bottom: 20px;
  z-index: 10;
  display: block;
  color: hsl(0, 0%, 20%);
  border: 0;
  outline: 0;
  text-transform: capitalize;
  min-width: 0;
  padding: 0px 5px;
  overflow: hidden;
  }
  
  .canvas-orientation img {
    width: 1.5rem;
    height: 2rem;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
}

.confirm-to-go-back p {
  text-align: center;
  font-weight: 600;
  /* font-size: 20px; */
}
.confirm-to-go-back p span {
  /* font-size: 16px; */
  background-color: #dff1fa;
  color:#26a3db;
  font-weight: 400;
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 10px;
  display: block;
  margin: 10px auto;
  width: fit-content;
}

.confirm-to-go-back button,
.confirm-to-go-back button:active,.confirm-to-go-back button:hover {
  padding: 5px 15px !important;
  border-radius: 8px !important;
  transition: 0.5s ease;
  font-weight: 400;
  /* width: 100px !important; */
  margin: 0 !important;
  height: 50px;
}
.confirm-to-go-back button.cancel-button,
.confirm-to-go-back button.cancel-button:active,.confirm-to-go-back button.cancel-button:hover {
  padding: 5px 10px !important;
  background-color: #e7e7e7 !important;
  color: #212529;
} 

body {
  min-height: 100vh;
}